import { filter } from './utils/arrays';
import { px } from "./units";
var delimited = function (delimiter) {
    return function () {
        return filter(arguments, function (s) { return s || s === 0; })
            .map(function (s) { return typeof s === 'number' ? px(s) : s.toString(); })
            .join(delimiter);
    };
};
export var params = delimited(' ');
export var list = delimited(',');
