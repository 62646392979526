import { cssFunction } from './utils/formatting';
/**
 * Helper for the linear-gradient function in CSS
 * https://drafts.csswg.org/css-images-3/#funcdef-linear-gradient
 */
export function linearGradient(position) {
    var colors = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        colors[_i - 1] = arguments[_i];
    }
    return cssFunction('linear-gradient', [position].concat(colors.map(flattenColorStops)));
}
/**
 * Helper for the repeating-linear-gradient function in CSS
 * https://drafts.csswg.org/css-images-3/#funcdef-repeating-linear-gradient
 */
export function repeatingLinearGradient(position) {
    var colors = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        colors[_i - 1] = arguments[_i];
    }
    return cssFunction('repeating-linear-gradient', [position].concat(colors.map(flattenColorStops)));
}
/**
 * Single CSSColorStop => string conversion is like:
 * 'x'=>'x'
 * ['x', '50%'] => 'x 50%'
 **/
function flattenColorStops(c) {
    return Array.isArray(c) ? c.map(function (s) { return s.toString(); }).join(' ') : c.toString();
}
