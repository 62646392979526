var functionExpression = /[\s]*([a-z-]+)[\s]*\([\s]*([^\)]+)[\s]*\)[\s]*/i;
var floatExpression = /^(\-?\d+\.?\d{0,5})/;
export var formatUnit = function (unit) { return function (val) { return (val + unit); }; };
export var toFloat = parseFloat;
export function ensurePercent(value) {
    return typeof value === 'number'
        ? value
        : toFloat(value) * .01;
}
export function formatPercent(value) {
    return (formatFloat(value * 100)) + '%';
}
/**
 * Returns a number formatted to a max number of 5 decimal places
 */
export function formatFloat(n) {
    return floatExpression.exec(n.toString())[1];
}
export function ensureLength(value) {
    if (value === null || value === undefined) {
        return undefined;
    }
    // convert to number
    var number = +value;
    // validate conversion worked (NaN will not equal NaN)
    if (number === number) {
        return value + 'px';
    }
    return value;
}
export function parseCSSFunction(stringValue) {
    var matches = functionExpression.exec(stringValue);
    if (!matches || !matches.length) {
        return undefined;
    }
    return [matches[1]].concat(matches[2].split(','));
}
export function cssFunction(functionName, params) {
    var parts = Array.prototype.join.call(params, ', ');
    return functionName + "(" + parts + ")";
}
export function createFunction(name) {
    return (function () {
        return cssFunction(name, arguments);
    });
}
