import { createFunction } from './utils/formatting';
/**
 * The CSS transform property lets you modify the coordinate space of the CSS visual formatting model. Using it, elements can be translated, rotated, scaled, and skewed.
 * Returns the transforms as a delimited string by space or returns 'none' if no arguments are provided
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform
 */
export function transform() {
    var transforms = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        transforms[_i] = arguments[_i];
    }
    return transforms.length ? transforms.join(' ') : 'none';
}
export var matrix = createFunction('matrix');
export var matrix3d = createFunction('matrix3d');
export var perspective = createFunction('perspective');
export var rotate = createFunction('rotate');
export var rotate3d = createFunction('rotate3d');
export var rotateX = createFunction('rotateX');
export var rotateY = createFunction('rotateY');
export var rotateZ = createFunction('rotateZ');
export var scale = createFunction('scale');
export var scale3d = createFunction('scale3d');
export var scaleX = createFunction('scaleX');
export var scaleY = createFunction('scaleY');
export var scaleZ = createFunction('scaleZ');
export var skew = createFunction('skew');
export var skewX = createFunction('skewX');
export var skewY = createFunction('skewY');
export var translate = createFunction('translate');
export var translate3d = createFunction('translate3d');
export var translateX = createFunction('translateX');
export var translateY = createFunction('translateY');
export var translateZ = createFunction('translateZ');
