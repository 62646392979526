import { coalesce } from './strings';
export function background() {
    var output = '';
    for (var i = 0; i < arguments.length; i++) {
        var background_1 = arguments[i];
        var backgroundSize = background_1.size
            ? '/' + background_1.size
            : '';
        var backgroundParts = [
            coalesce(background_1.image),
            coalesce(background_1.position) + backgroundSize,
            coalesce(background_1.repeat),
            coalesce(background_1.origin),
            coalesce(background_1.clip),
            coalesce(background_1.attachment),
            coalesce(background_1.color),
        ];
        var backgroundString = backgroundParts.filter(Boolean).join(' ');
        output += (output.length && backgroundString ? ', ' : '') + backgroundString;
    }
    return output;
}
