import { ensureLength } from './utils/formatting';
import { params } from './lists';
/**
 * Returns the value with '' around it.  Any 's will be escaped \' in the output
 */
export function border(p) {
    return params(p.color, p.style, ensureLength(p.width));
}
export var borderColor = params;
export var borderStyle = params;
export var borderWidth = params;
