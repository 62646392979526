/**
 * Returns the value with '' around it.  Any 's will be escaped \' in the output
 */
export function calc(exp) {
    return "calc(" + exp + ")";
}
/**
 * Returns the value with '' around it.  Any 's will be escaped \' in the output
 */
export function quote(val) {
    var val2 = (val || val === 0 ? val.toString() : '').replace(/\'/g, "\\'");
    return "'" + val2 + "'";
}
/**
 * Returns the value with !important on the end.  If the value provided is a CSSHelper, it will
 * be converted to a string by necessity, but will look like it is the original type to TypeScript.
 */
export function important(val) {
    if (!val && val !== 0) {
        return '';
    }
    return val.toString() + " !important";
}
/**
 * Returns the string in a url()
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/url
 */
export function url(val) {
    return "url(" + (val || '') + ")";
}
/**
 * Returns the value as a string or an empty string if null or undefined.
 * @param value
 * @param fallbackValue
 */
export function coalesce(value) {
    return !value && value !== 0 ? '' : value.toString();
}
