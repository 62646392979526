import { formatUnit } from './utils/formatting';
/**
 * Returns the number with a suffix of %
 */
export var percent = formatUnit('%');
/**
 * Returns the number with a suffix of deg
 */
export var deg = formatUnit('deg');
/**
 * Returns the number with a suffix of em
 */
export var em = formatUnit('em');
/**
 * Returns the number with a suffix of ex
 */
export var ex = formatUnit('ex');
/**
 * Returns the number with a suffix of px
 */
export var px = formatUnit('px');
/**
 * Returns the number with a suffix of rad
 */
export var rad = formatUnit('rad');
/**
 * Returns the number with a suffix of rem
 */
export var rem = formatUnit('rem');
/**
 * Returns the number with a suffix of vh
 */
export var viewHeight = formatUnit('vh');
/**
 * Returns the number with a suffix of vw
 */
export var viewWidth = formatUnit('vw');
/**
 * Returns the number with a suffix of turn
 */
export var turn = formatUnit('turn');
